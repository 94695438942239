const jp = require("jsonpath");
import { _http } from "./backend.js";

const gds_url = "/gdsapi/rest/v3/clientservice.json";
const chart_url = "/gdsapi/rest/chart/v1/chartseriesservice.json";

export default {
  fetchChartIQSymbols: async (query) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(chart_url, {
      inputRequests: [
        {
          type: "SYMBOLS",
          identifier: query,
        },
      ],
    });

    const companyIds = jp.query(response.data, "GDSSDKResponse.ciq_ID")[0];
    const symbols = jp.query(response.data, "GDSSDKResponse.symbols")[0];
    const ret = [];
    for (let i = 0; i < companyIds.length && symbols.length; i++) {
      const [ticker, name, exchange] = symbols[i].split("|");
      let symbol = ticker + ":" + exchange;

      // it's not listed company if ticker is not available
      if (ticker == "Data Unavailable") {
        continue;
      }
      ret.push({
        display: [symbol, name, exchange],
        data: {
          symbol: symbol,
          name: name,
          exchDisp: exchange,
        },
      });
    }
    return ret;
  },
  fetchChartLabels: async (query) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: [
        {
          function: "GDSP",
          identifier: query,
          mnemonic: "IQ_COMPANY_TICKER",
        },
        {
          function: "GDSP",
          identifier: query,
          mnemonic: "IQ_COMPANY_NAME",
        },
      ],
    });
    return {
      errMsg: jp.query(response.data, "GDSSDKResponse[0].ErrMsg"),
      ticker: jp.query(response.data, "GDSSDKResponse[0]..Row[0]")[0],
      name: jp.query(response.data, "GDSSDKResponse[1]..Row[0]")[0],
    };
  },
  fetchChartIQPrices: async (companyId, startDate, endDate) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(chart_url, {
      inputRequests: [
        {
          type: "STOCKPRICES",
          identifier: companyId,
          properties: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      ],
    });

    return jp.query(response.data, "GDSSDKResponse")[0];
  },
  fetchChartIQVolumesAndKeyDevs: async (    
    companyId,
    startDate,
    endDate
  ) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(chart_url, {
      inputRequests: [
        {
          type: "KEYDEV_DATA",
          identifier: companyId,
          properties: {
            startDate: startDate,
            endDate: endDate,
            keyDevFilters: "C5,C6,C10",
          },
        },
      ],
    });
    
    return {
      volumes: jp.query(response.data, "GDSSDKResponse.PRICING_DATA")[0],
      keydevs: jp.query(response.data, "GDSSDKResponse.KEY_DEV_DATA")[0]
    };
  },
}