<template>
  <div class="container">
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Headline</th>
          <th>Type</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in this.keyDevelopments" v-bind:key="index">
          <td>{{ item[0].format('MMM DD YYYY hh:mm A') }}</td>
          <td>{{ item[1] }}</td>
          <td>{{ item[2] }}</td>
          <td>{{ item[3] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  const jp = require('jsonpath');
  const moment = require('moment');
  import api from '@/api/dashboard1.js';

  export default {
    name: 'KeyDevelopments',
    data: () => {
      return {
        keyDevelopments: [],
      };
    },
    methods: {
      render: async function (q) {
        this.keyDevelopments.splice(0, this.keyDevelopments.length);
        let r = await api.fetchKeyDevelopmentList(q);
        const keyDevelopmentIds = jp.query(r, '$..Row[0]').slice(0, 20);
        r = await api.fetchKeyDevelopmentDetails(keyDevelopmentIds);
        const keyDevelopmentDetails = jp.query(r, '$..Row[0]');
        const chunkSize =
          keyDevelopmentDetails.length / keyDevelopmentIds.length;
        for (let i = 0; i < keyDevelopmentDetails.length; i += chunkSize) {
          keyDevelopmentDetails[i] = moment(
            keyDevelopmentDetails[i],
            'MM/DD/YYYY h:mm:ss A'
          );
          this.keyDevelopments.push(
            keyDevelopmentDetails.slice(i, i + chunkSize)
          );
        }
      },
    },
  };
</script>
<style scoped>
  .container {
    width: 1200px;
    margin: 0 auto;
  }

  .container table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 1em;
  }

  .container td,
  th {
    padding: 10px;
    background-color: #fafafa;
    text-align: left;
    border: 2px solid #ddd;
  }

  .container td {
    text-align: left;
  }

  .container thead th {
    text-align: center;
  }

  .container td {
    background-color: #fefefe;
  }
</style>
