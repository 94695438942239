import axios from 'axios';

export const _http = (accessToken) => {
  return axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  });
};

export const _xttp = (accessToken) => {
  return axios.create({
    baseURL: process.env.VUE_APP_XAPI_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const _ittp = (token) => {
  const config = {
    baseURL: process.env.VUE_APP_IHS_N6_URL,
  };
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return axios.create(config);
};