const jp = require('jsonpath');
import { _http, _xttp } from './backend.js';

const gds_url = '/gdsapi/rest/v3/clientservice.json';

export default {
  quickComps: async (companyId) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: [
        {
          function: 'GDSPV',
          identifier: companyId,
          mnemonic: 'IQ_SP_RATING_ANALYST_PEERS',
          properties: { startRank: '1', endRank: '3' },
        },
      ],
    });
    return jp.query(
      response.data,
      "GDSSDKResponse[?(@.Mnemonic == 'IQ_SP_RATING_ANALYST_PEERS')].Rows[*].Row[0]"
    );
  },
  ticker: async (companyId) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: [
        {
          function: 'GDSP',
          identifier: companyId,
          mnemonic: 'IQ_COMPANY_TICKER',
        },
      ],
    });
    return jp.query(
      response.data,
      "GDSSDKResponse[?(@.Mnemonic == 'IQ_COMPANY_TICKER')].Rows[*].Row[0]"
    )[0];
  },
  companyIds: async (symbols) => {
    const inputRequests = symbols.map((symbol) => ({
      function: 'GDSP',
      identifier: symbol,
      mnemonic: 'IQ_COMPANY_ID',
    }));
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: inputRequests,
    });
    return jp.query(
      response.data,
      "GDSSDKResponse[?(@.Mnemonic == 'IQ_COMPANY_ID')].Rows[*].Row[0]"
    );
  },
  companyNames: async (symbols) => {
    const inputRequests = symbols.map((symbol) => ({
      function: 'GDSP',
      identifier: symbol,
      mnemonic: 'IQ_COMPANY_NAME',
    }));
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: inputRequests,
    });
    return jp.query(
      response.data,
      "GDSSDKResponse[?(@.Mnemonic == 'IQ_COMPANY_NAME')].Rows[*].Row[0]"
    );
  },
  fetchArticleList: async (companyId) => {
    let c = companyId.replace('IQ', '');
    const accessToken = localStorage.getItem('accessToken');
    const response = await _xttp(accessToken).post(
      '/rxresearch/api/v1/articlelist',
      {
        identifierType: 'CiqCompanyId',
        identifierValue: [c],
        articleType: ['FULL'],
        page: 1,
        pageSize: 10,
      }
    );
    if (response.data.error) {
      return response.data;
    }
    return jp.query(response.data, '$.results')[0];
  },
  fetchArticleContent: async (articleId) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _xttp(accessToken).post(
      '/rxresearch/api/v1/articlecontent',
      {
        articleId: articleId,
        responseType: 'XML',
      }
    );
    if (response.data.error) {
      return response.data;
    }

    return jp.query(response.data, '$.articleContentResponse.articleXML')[0];
  },
  fetchPeriodDates: async (companyIds) => {
    const inputRequests = companyIds.map((companyId) => ({
      function: 'GDSP',
      identifier: companyId,
      mnemonic: 'IQ_PERIODDATE_BS',
      properties: {
        PeriodType: 'IQ_FY',
        RestatementTypeId: 'LC',
      },
    }));
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: inputRequests,
    });
    return jp.query(
      response.data,
      "GDSSDKResponse[?(@.Mnemonic == 'IQ_PERIODDATE_BS')].Rows[*].Row[0]"
    );
  },
  fetchFinancials: async (companyId) => {
    const inputRequests = [
      'IQ_TOTAL_REV',
      'IQ_NI',
      'IQ_GROSS_MARGIN',
      'IQ_RETURN_ASSETS',
      'IQ_RETURN_EQUITY',
      'IQ_TOTAL_DEBT_EQUITY',
    ].map((m) => ({
      function: 'GDSP',
      identifier: companyId,
      mnemonic: m,
      properties: {
        PeriodType: 'IQ_FY',
        RestatementTypeId: 'LC',
        currencyId: 'USD',
      },
    }));
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: inputRequests,
    });
    return {
      totalRevenue: jp.query(
        response.data,
        "GDSSDKResponse[?(@.Mnemonic == 'IQ_TOTAL_REV')].Rows[*].Row[0]"
      )[0],
      netIncome: jp.query(
        response.data,
        "GDSSDKResponse[?(@.Mnemonic == 'IQ_NI')].Rows[*].Row[0]"
      )[0],
      grossMargin: jp.query(
        response.data,
        "GDSSDKResponse[?(@.Mnemonic == 'IQ_GROSS_MARGIN')].Rows[*].Row[0]"
      )[0],
      returnOnAsset: jp.query(
        response.data,
        "GDSSDKResponse[?(@.Mnemonic == 'IQ_RETURN_ASSETS')].Rows[*].Row[0]"
      )[0],
      returnOnEquity: jp.query(
        response.data,
        "GDSSDKResponse[?(@.Mnemonic == 'IQ_RETURN_EQUITY')].Rows[*].Row[0]"
      )[0],
      totalDebtEquity: jp.query(
        response.data,
        "GDSSDKResponse[?(@.Mnemonic == 'IQ_TOTAL_DEBT_EQUITY')].Rows[*].Row[0]"
      )[0],
    };
  },
};
