const jp = require("jsonpath");
import { _http } from './backend.js';
const gds_url = "/gdsapi/rest/v3/clientservice.json";
const chart_url = "/gdsapi/rest/chart/v1/chartseriesservice.json";

export default {
  fetchChartPrices: async (companyId, startDate, endDate) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(chart_url, {
      inputRequests: [
        {
          type: "STOCKPRICES",
          identifier: companyId,
          properties: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      ],
    });

    return jp
      .query(response.data, "GDSSDKResponse")[0]
      .map((e) => [Date.parse(e.DT), e.Open, e.High, e.Low, e.Close]);
  },
  fetchVolumesAndKeyDevs: async (companyId, startDate, endDate) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(chart_url, {
      inputRequests: [
        {
          type: "KEYDEV_DATA",
          identifier: companyId,
          properties: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      ],
    });

    let volumes = jp
      .query(response.data, "GDSSDKResponse.PRICING_DATA")[0]
      .map((e) => [Date.parse(e.DT), e.Volume]);
    let keydevs = jp
      .query(response.data, "GDSSDKResponse.KEY_DEV_DATA")[0]
      .map((e) => ({ date: e.data.timestamp, headline: e.data.headline }));
    return {
      volumes: volumes,
      keydevs: keydevs,
    };
  },
  fetchChartLabels: async (query) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await _http(accessToken).post(gds_url, {
      inputRequests: [
        {
          function: "GDSP",
          identifier: query,
          mnemonic: "IQ_COMPANY_TICKER",
        },
        {
          function: "GDSP",
          identifier: query,
          mnemonic: "IQ_COMPANY_NAME",
        },
      ],
    });
    return {
      errMsg: jp.query(response.data, "GDSSDKResponse[0].ErrMsg"),
      ticker: jp.query(response.data, "GDSSDKResponse[0]..Row[0]")[0],
      name: jp.query(response.data, "GDSSDKResponse[1]..Row[0]")[0],
    };
  },
};
