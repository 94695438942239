<template>
  <div id="chartDashboard5">
    <cq-context
      ><cq-chart-instructions role="contentinfo"></cq-chart-instructions>
      <cq-color-picker></cq-color-picker>

      <div class="ciq-nav full-screen-hide" role="navigation">
        <!-- enables the more button when in break-sm mode -->
        <div class="sidenav-toggle ciq-toggles">
          <cq-toggle
            class="ciq-sidenav"
            cq-member="sidenav"
            cq-toggles="sidenavOn,sidenavOff"
            cq-toggle-classes="active,"
            keyboard-navigation="false"
            ><span></span>
            <cq-tooltip>More</cq-tooltip>
          </cq-toggle>
        </div>

        <cq-clickable
          role="button"
          class="symbol-search"
          cq-selector="cq-lookup-dialog"
          cq-method="open"
        >
          <span class="ciq-lookup-icon"></span>
          <cq-tooltip>Symbol Search</cq-tooltip>
        </cq-clickable>

        <cq-clickable
          role="button"
          class="symbol-search"
          cq-selector="cq-lookup-dialog"
          cq-method="open"
          comparison="true"
        >
          <span class="ciq-comparison-icon"></span>
          <cq-tooltip>Add Comparison</cq-tooltip>
        </cq-clickable>

        <!-- any entry in this div will be shown in the side navigation bar in break-sm mode -->
        <cq-side-nav cq-on="sidenavOn">
          <div class="icon-toggles ciq-toggles">
            <cq-toggle class="ciq-draw" cq-member="drawing"
              ><cq-help help-id="drawing_tools_toggle"></cq-help><span></span
              ><cq-tooltip>Draw</cq-tooltip></cq-toggle
            >

            <cq-info-toggle-dropdown>
              <cq-toggle class="ciq-CH" cq-member="crosshair">
                <span></span>
                <cq-tooltip>Crosshair<span> (Alt + \)</span></cq-tooltip>
              </cq-toggle>

              <cq-menu class="ciq-menu toggle-options collapse">
                <span></span>
                <cq-menu-dropdown>
                  <cq-item cq-member="crosshair"
                    >Hide Heads-Up Display<span class="ciq-radio"
                      ><span></span></span
                  ></cq-item>
                  <cq-item cq-member="headsUp-static"
                    >Show Heads-Up Display<span class="ciq-radio"
                      ><span></span></span
                  ></cq-item>
                </cq-menu-dropdown>
              </cq-menu>
            </cq-info-toggle-dropdown>

            <cq-info-toggle-dropdown>
              <cq-toggle class="ciq-HU" cq-member="headsUp">
                <span></span>
                <cq-tooltip>Info</cq-tooltip>
              </cq-toggle>

              <cq-menu class="ciq-menu toggle-options collapse tooltip-ui">
                <span></span>
                <cq-menu-dropdown>
                  <cq-item cq-member="headsUp-dynamic"
                    >Show Dynamic Callout<span class="ciq-radio"
                      ><span></span></span
                  ></cq-item>
                  <cq-item cq-member="headsUp-floating"
                    >Show Tooltip<span class="ciq-radio"><span></span></span
                  ></cq-item>
                </cq-menu-dropdown>
              </cq-menu>
            </cq-info-toggle-dropdown>

            <cq-toggle
              class="ciq-DT tableview-ui"
              cq-member="tableView"
              role="button"
              aria-pressed="false"
            >
              <span></span><cq-tooltip>Table View</cq-tooltip>
            </cq-toggle>
          </div>
        </cq-side-nav>

        <div class="ciq-menu-section">
          <div class="ciq-dropdowns">
            <cq-menu class="ciq-menu ciq-display collapse">
              <cq-clickable
                cq-tooltip-activator=""
                stxbind="Layout.chartType"
                ciq-no-icon-text="Display"
              >
                <span ciq-menu-icon=""></span>
                <cq-tooltip></cq-tooltip>
              </cq-clickable>
              <cq-menu-dropdown>
                <cq-menu-dropdown-section class="chart-types">
                  <cq-heading>Chart Types</cq-heading>
                  <cq-menu-container
                    cq-name="menuChartStyle"
                  ></cq-menu-container>
                </cq-menu-dropdown-section>
                <cq-menu-dropdown-section
                  class="chart-aggregations advanced-ui"
                >
                  <cq-separator></cq-separator>
                  <cq-heading>Aggregated Types</cq-heading>
                  <cq-menu-container
                    cq-name="menuChartAggregates"
                  ></cq-menu-container>
                </cq-menu-dropdown-section>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu ciq-period">
              <span
                ><cq-clickable stxbind="Layout.periodicity"
                  >1D</cq-clickable
                ></span
              >
              <cq-menu-dropdown>
                <cq-menu-container
                  cq-name="menuPeriodicity"
                ></cq-menu-container>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu ciq-views collapse">
              <span>Views</span>
              <cq-menu-dropdown>
                <cq-views></cq-views>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu ciq-studies collapse" cq-focus="input">
              <span>Studies</span>
              <cq-menu-dropdown>
                <cq-study-menu-manager></cq-study-menu-manager>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu stx-markers collapse">
              <span>Events</span>
              <cq-menu-dropdown>
                <div class="markers-ui" style="display: inherit">
                  <cq-heading>Chart Events</cq-heading>
                  <cq-item stxtap="Markers.showMarkers('square')" cq-no-close=""
                    >Key Developments<span class="ciq-switch"
                      ><span></span
                    ></span>
                  </cq-item>
                </div>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu ciq-preferences collapse">
              <span></span>
              <cq-menu-dropdown>
                <cq-menu-dropdown-section class="chart-preferences">
                  <cq-heading>Chart Preferences</cq-heading>
                  <cq-menu-container
                    cq-name="menuChartPreferences"
                  ></cq-menu-container>
                  <cq-separator></cq-separator>
                </cq-menu-dropdown-section>
                <cq-menu-dropdown-section class="y-axis-preferences">
                  <cq-heading>Y-Axis Preferences</cq-heading>
                  <cq-menu-container
                    cq-name="menuYAxisPreferences"
                  ></cq-menu-container>
                  <cq-separator></cq-separator>
                </cq-menu-dropdown-section>
                <cq-menu-dropdown-section class="chart-addons">
                  <cq-heading>Additional Features</cq-heading>
                  <cq-menu-container cq-name="menuAddOns"></cq-menu-container>
                  <cq-separator></cq-separator>
                </cq-menu-dropdown-section>
                <cq-menu-dropdown-section class="chart-theme">
                  <cq-heading>Themes</cq-heading>
                  <cq-themes></cq-themes>
                  <cq-separator></cq-separator>
                </cq-menu-dropdown-section>
                <cq-menu-dropdown-section class="chart-locale">
                  <cq-heading>Locale</cq-heading>
                  <cq-item keyboard-selectable="false"
                    ><cq-clickable
                      cq-selector="cq-timezone-dialog"
                      cq-method="open"
                      keyboard-selectable="true"
                      >Change Timezone</cq-clickable
                    ></cq-item
                  >
                  <cq-item stxsetget="Layout.Language()">
                    <cq-flag></cq-flag>
                    <cq-language-name>Change Language</cq-language-name>
                  </cq-item>
                </cq-menu-dropdown-section>
              </cq-menu-dropdown>
            </cq-menu>
          </div>
          <div class="ciq-toggles"></div>
        </div>
      </div>
      <!-- End Navbar -->
      <div class="ciq-chart-area" role="main">
        <div class="ciq-chart">
          <cq-message-toaster
            defaultdisplaytime="10"
            defaulttransition="slide"
            defaultposition="top"
          ></cq-message-toaster>
          <cq-palette-dock>
            <div class="palette-dock-container">
              <cq-drawing-palette
                class="palette-drawing grid palette-hide"
                docked="true"
                orientation="vertical"
                min-height="300"
                cq-drawing-edit="none"
              ></cq-drawing-palette>
              <cq-drawing-settings
                class="palette-settings"
                docked="true"
                hide="true"
                orientation="horizontal"
                min-height="40"
                cq-drawing-edit="none"
              ></cq-drawing-settings>
            </div>
          </cq-palette-dock>
          <div class="chartContainer">
            <!-- tooltip markup is required only if addon tooltip is used and customization is required -->
            <table class="hu-tooltip">
              <caption>
                Tooltip
              </caption>
              <tbody>
                <tr hu-tooltip-field="" class="hu-tooltip-sr-only">
                  <th>Field</th>
                  <th>Value</th>
                </tr>
                <tr hu-tooltip-field="DT">
                  <td class="hu-tooltip-name">Date/Time</td>
                  <td class="hu-tooltip-value"></td>
                </tr>
                <tr hu-tooltip-field="Close">
                  <td class="hu-tooltip-name"></td>
                  <td class="hu-tooltip-value"></td>
                </tr>
              </tbody>
            </table>
            <cq-chart-title
              cq-marker=""
              cq-activate-symbol-search-on-click=""
            ></cq-chart-title>
            <cq-chartcontrol-group
              class="full-screen-show"
              cq-marker=""
            ></cq-chartcontrol-group>
            <cq-chart-legend></cq-chart-legend>
            <cq-loader></cq-loader>
          </div>
          <!-- End Chart Container -->
        </div>
        <!-- End Chart Box -->
      </div>
    </cq-context>
  </div>
</template>
<script>
  import { markers, quoteFeed, symbolLookupBase } from 'chartiq/js/standard';
  import 'chartiq/js/advanced';
  import 'chartiq/js/addOns';
  import { CIQ } from 'chartiq/js/components';
  import getDefaultConfig from 'chartiq/js/defaultConfiguration.js';
  import 'chartiq/js/thirdparty/custom-elements.min.js';
  import api from '@/api/dashboard5';
  let moment = require('moment');

  export default {
    name: 'DashBoard5',
    data: () => {
      return {
        query: 'AAPL:NASDAQGS',
        endDate: new Date(),
      };
    },
    methods: {
      refresh: async function () {
        // Remove existing lookup dialogs
        const divs = document.querySelectorAll('.cq-dialogs');
        for (const div of divs) {
          div.remove();
        }

        CIQ.activateImports(markers, quoteFeed, symbolLookupBase);
        CIQ.ChartEngine.Driver.Lookup.ChartIQ = function (exchanges) {
          this.exchanges = exchanges;
        };
        CIQ.inheritsFrom(
          CIQ.ChartEngine.Driver.Lookup.ChartIQ,
          CIQ.ChartEngine.Driver.Lookup
        );
        CIQ.ChartEngine.Driver.Lookup.ChartIQ.prototype.acceptText =
          async function (text, filter, maxResults, cb) {
            const response = await api.fetchChartIQSymbols(text);
            cb(response);
          };

        let labels = await api.fetchChartLabels(this.query);
        if (labels.errMsg != '') {
          alert(this.query + ' is not valid identifier : ' + labels.errMsg);
          return;
        }

        let fetch = async (symbol, startDate, endDate, params, cb) => {
          let s = moment(startDate).format('MM/DD/YYYY');
          let e = moment(endDate).format('MM/DD/YYYY');
          try {
            let quotes = await api.fetchChartIQPrices(symbol, s, e);
            let vk = await api.fetchChartIQVolumesAndKeyDevs(symbol, s, e);
            let volumes = vk['volumes'];
            let keydevs = vk['keydevs'];

            quotes.forEach((e) => {
              const v = volumes.find((i) => i.DT == e.DT);
              const k = keydevs.filter((i) => i.Date == e.DT);
              e['Volume'] = v['Volume'] * 1000000;
              e['Keydevs'] = k;
            });
            cb({
              quotes: quotes,
              moreAvailable: quotes.length > 0,
            });
          } catch (e) {
            cb({ error: e });
          }
        };
        let quotefeed = {
          // eslint-disable-next-line
          fetchInitialData: async function (
            symbol,
            startDate,
            endDate,
            params,
            cb
          ) {
            console.log('Asking for initial data...');
            fetch(symbol, startDate, endDate, params, cb);
          },
          // eslint-disable-next-line
          fetchUpdateData: async function (symbol, startDate, params, cb) {
            console.log('Asking for update data...');
          },
          // eslint-disable-next-line
          fetchPaginationData: async function (
            symbol,
            startDate,
            endDate,
            params,
            cb
          ) {
            console.log('Asking for pagination data...');
            fetch(symbol, startDate, endDate, params, cb);
          },
        };

        var markerfeed = function (stx) {
          this.stx = stx;
          this.activeLabels = [];
        };
        markerfeed.prototype.createMarkers = function (label) {
          this.stx.masterData.forEach((e) => {
            if (!e.Keydevs) return;
            e.Keydevs.forEach((k) => {
              new CIQ.Marker({
                stx: this.stx,
                label: label,
                xPositioner: 'date',
                x: new Date(k.Date),
                node: new CIQ.Marker.Simple({
                  type: label,
                  headline: k.data.headline,
                  category: 'news',
                  story: k.data.story,
                }),
              });
            });
          });
          return label;
        };
        markerfeed.prototype.processLabelsAndDraw = function (labels) {
          this.activeLabels = this.activeLabels.concat(labels);
          this.stx.draw();
          return labels;
        };
        markerfeed.prototype.hideMarkers = function (type) {
          let stx = this.stx;
          if (type) {
            CIQ.Marker.removeByLabel(stx, type);
            this.activeLabels = this.activeLabels.filter(
              (label) => label != type
            );
          } else {
            this.activeLabels.forEach(function (label) {
              CIQ.Marker.removeByLabel(stx, label);
            });
            this.activeLabels = [];
          }
        };
        markerfeed.prototype.showMarkers = function (type) {
          return this.processLabelsAndDraw(this.createMarkers(type));
        };

        const config = getDefaultConfig({
          markerFeed: markerfeed,
          quoteFeed: quotefeed,
        });
        config.chartId = 'chartDashboard5';
        config.initialSymbol = {
          symbol: 'AAPL:NASDAQGS',
          name: 'Apple Inc.',
          exchDisp: 'NASDAQGS',
        };
        const stxx = config.createChart(
          document.getElementById('chartDashboard5')
        );
        stxx.setRange({
          dtLeft: moment(new Date()).subtract(2, 'months').toDate(),
          dtRight: new Date(),
        });
        stxx.loadChart();
      },
    },
    mounted: async function () {
      await this.refresh();
    },
  };
</script>
<style>
  @import '../../node_modules/chartiq/css/chartiq.css';
  @import '../../node_modules/chartiq/css/stx-chart.css';
</style>
