import axios from 'axios';
const moment = require('moment');

const accessToken = localStorage.getItem('accessToken');
const storedExpirationTime = localStorage.getItem('expirationTime');
const isTokenExpired =
  storedExpirationTime == null ||
  moment(storedExpirationTime).isBefore(moment());

const initialState =
  accessToken && !isTokenExpired
    ? { status: { loggedIn: true }, accessToken }
    : { status: { loggedIn: false }, accessToken: null };

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login: async ({ commit }, user) => {
      let url = '/gdsapi/rest/authenticate/api/v1/token';
      const data = new URLSearchParams();
      data.append('username', user.username);
      data.append('password', user.password);

      let headers = {
        'Content-type': 'application/x-www-form-urlencoded',
        accept: 'application/json',
      };
      const response = await axios.post(url, data, { headers: headers });
      if (response.status == 200) {
        const accessToken = response.data.access_token;
        const expiresInSeconds = response.data.expires_in_seconds;
        const expirationTime = moment().add(expiresInSeconds, 'seconds');

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('expirationTime', expirationTime.toISOString());
        commit('loginSuccess', accessToken);
        return Promise.resolve(accessToken);
      } else {
        commit('loginFailure');
        return Promise.reject(null);
      }
    },
    logout: async ({ commit }) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expirationTime');
      commit('logout');
    },
  },
  mutations: {
    loginSuccess(state, accessToken) {
      state.status.loggedIn = true;
      state.accessToken = accessToken;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.accessToken = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.accessToken = null;
    },
  },
};
